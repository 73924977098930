.content {
    padding-left: 0rem;
}
    
.content-parent {
    height: 0px;
    overflow: hidden;
    align-items: left;
    justify-content: left;
    transition: height ease 0.9s;
}
    
.content-show {
    height: 200px;
}

.button {
    background-color: rgb(249, 242, 217);
    border: none;
    color: rgb(0, 0, 0);
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Courier New', Courier, monospace;
  }
