.vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.socials {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    padding-bottom: 20px;
    gap: 15px;
}

.socials a{
    text-decoration: none;
    color:rgb(0, 0, 0);
}

.icons {
    padding:10px;
}

.mid {
    display: flex;
    background-color: rgb(255, 239, 158);
    width: 50%;
    height: 20%;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 5%;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;

}

.mid a {
    text-decoration: none;
    color:rgb(0, 0, 0);
}
    
.mid a:hover{
color:rgb(247, 7, 7);
}

.mid a:focus{
    color:rgb(247, 7, 7);
}

.left {
display: flex;
width: 40%;
font-size: 50px;
font-weight: bolder;
padding: 18px 17px;
color: #000000;
font-weight: 800;
text-align: center;
justify-content: center;
align-items: center;
}

.left a{
text-decoration:none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 15%;
    right:10%;
    width: 25rem;
    z-index: -1;
    transform: rotate(10deg);
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .vertical {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .socials {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        padding-bottom: 20px;
        gap: 15px;
    }
    
    .socials a{
        text-decoration: none;
        color:rgb(0, 0, 0);
    }
    
    .icons {
        padding:10px;
    }
    
    .mid {
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 239, 158);;
        width: 100%;
        height: 10%;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        padding: 10px;
        /* padding-bottom: 10px; */
    }
    
    .mid a {
        text-decoration: none;
        color:rgb(0, 0, 0);
    }
        
    .mid a:hover{
    color:rgb(247, 7, 7);
    }
    
    .mid a:focus{
        color:rgb(247, 7, 7);
    }
    
    .left {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 50px;
    font-weight: bolder;
    padding: 18px 17px;
    color: #000000;
    font-weight: 800;
    text-align: center;
    justify-content: center;
    align-items: center;
    }
    
    .left a{
    text-decoration:none;
    }
    
    .overlay {
        position: absolute;
        top: 0;
        left: 20%;
        right:10%;
        width: 20rem;
        z-index: -1;
        transform: rotate(5deg);
        margin: auto;
    }
}