body {
    background-color: rgb(249, 242, 217);
}

.body-container {
    width: 50%;
    font-size: 16px;
    overflow-x: hidden;
}

.vertical-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    align-self: center;
    justify-content: center;
    display: flex;
    padding: 20px;
}

.text-center {
    text-align: center;
    font-size: 20px;
}


.block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.heading {
    text-align: left;
}

a:active {
    color: red;
}

a {
    color: red;
}

a:visited {
    color: rgb(19, 109, 205);
}




@media only screen and (max-width: 800px) {
    .container {
        justify-content: center;
        align-items: center;
        width:100vw;
    }
    
    .vertical-container {
        display: flex;
        flex-direction: column;
        padding: 20px;
        justify-content: center;
        align-items: center;
    }
    
    .horizontal-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px;
    
    }
    
    .body {
        width: 80%;
    }
    
    .image {
        align-self: center;
        justify-content: center;
        display: flex;
        padding: 20px;
    }
    
    .text-center {
        text-align: center;
        font-size: 15px;
        overflow-wrap:break-word
    }
    
    .block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    
    .shorts {
        width: 100%;
    }
    
    .heading {
        text-align: left;
    }
}